<template>
  <SearchCliente :nombrCC="nombrCC" :clienNom="clienNom" :centrosPe="centrosPe" />
  <div class="app-container">
    <div class="order-container">
      <h2>Resumen del Pedido</h2>

      <!-- Mensaje de no hay productos -->
      <div v-if="order.length === 0" class="no-products">
        No hay productos en el pedido.
      </div>

      <!-- Lista de productos -->
      <div class="products">
        <div v-for="product in order" :key="product.id" class="product-card">
          <!-- Información del producto -->

          <div class="product-info">
            <div class="product-details">

              <img :src="getProductImage(product?.images)" alt="Imagen del producto" class="popup-image"
                @error="(e) => e.target.src = 'https://ep1.elpunto.com.co/puntocadenaimages/000000000.jpg'" />

              <h3 class="infonNa">{{ product.name }}</h3>

              <table class="tabli">
                <tbody>
                  <!-- Fila 1 -->
                  <tr class="table-row">
                    <td><strong class="titulo">IVA:</strong> {{ product.iva }}</td>
                    <td><strong class="titulo">ANTES DEL IVA:</strong> {{ formatcantidad(product.price) }}</td>
                  </tr>
                  <!-- Fila 2 -->
                  <tr class="table-row">
                    <td><strong class="titulo">CANTIDAD:</strong> {{ formatcantidad(product.cantidad) }}</td>
                    <td><strong class="titulo">Disp:</strong> {{ product.availibilityCount }}</td>
                  </tr>
                  <!-- Fila 2 -->
                  <tr class="table-row">
                    <td><strong class="titulo">Tipo:</strong> {{ product.tipoabc }}</td>
                    <td>
                      <!-- <strong class="titulo">Nota:</strong>
                      <input type="text" v-model="product.nota" @input="addNote(product)"
                        placeholder="Escribe tu nota aquí" class="input-nota" /> -->

                      <q-btn icon="add_circle" color="primary" @click="openNoteDialogProduct(product)" round dense />

                    </td>
                  </tr>
                </tbody>
              </table>



              <p class="product-price">
                ${{
                  formatcantidad(
                    (product.price * product.iva) / 100 + product.price
                  )
                }}
              </p>
            </div>

            <div class="product-actions">
              <p class="info">Cantidad:</p>
              <button @click="decreasecantidad(product)" class="cantidad-button">
                -
              </button>
              <input type="number" v-model.number="product.cantidad" @input="valorCliente(product)"
                :class="{ 'input-rojo': product.cantidad > product.availibilityCount }" class="cantidad-input" />
              <button @click="increasecantidad(product)" class="cantidad-button">
                +
              </button>
              <button class="remove-from-list-button" @click="removeFromOrder(product.id)">
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Resumen del precio total -->
      <div class="resumen">
        <div class="order-summary">
          <p class="total-price">Total items: {{ order.length }}</p>
        </div>

        <div class="order-summary">
          <p class="total-price">Subtotal: ${{ formatcantidad(calculateTotalPrice()) }}</p>
        </div>
        <div class="order-summary">
          <p class="total-price">Iva: ${{ formatcantidad(calculateTotalIva()) }}</p>
        </div>
        <div class="order-summary">
          <p class="total-price">Total: ${{ formatcantidad(calculTotalConIva()) }}</p>
        </div>

        <router-link to="/logged-in" class="back-to-products-link">
          Volver a Productos
        </router-link>
        <button class="confirm-order-button" @click="confirmWithNote">
          Confirmar Pedido
        </button>

        <div v-if="isLoading" class="loading-overlay">
          <div class="loading-popup">
            <atom-spinner :animation-duration="1000" :size="60" color="#ff1d5e" />
            <p>{{ loadingMessage }}</p>
          </div>
        </div>

        <!-- Popup para la imagen del producto -->
        <div v-if="showPopup" class="popup-overlay" @click="closeImagePopup">
          <div class="popup-content" style="width: 65%" @click.stop>
            <button class="close-popup-button" @click="closeImagePopup">
              <font-awesome-icon :icon="['fas', 'xmark']" />
            </button>
            <img :src="getProductImage(selectedProduct?.fotoEcom)" alt="Imagen del producto" class="popup-image" />
            <p>{{ selectedProduct.name }}</p>

            <div class="cantidad-container">
              <button @click="decreasecantidad(selectedProduct)" class="cantidad-button">
                -
              </button>
              <input type="number" v-model.number="selectedProduct.cantidad" min="1"
                :max="selectedProduct.availibilityCount" placeholder="Cantidad" class="cantidad-input" />
              <button @click="increasecantidad(selectedProduct)" class="cantidad-button">
                +
              </button>
            </div>
            <button class="update-cantidad-button" @click="updatecantidad(selectedProduct.id)">
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Diálogo para agregar una nota pedido -->
  <q-dialog v-model="showNoteDialog">
    <q-card>
      <q-card-section>
        <div class="q-mb-md">
          <q-input v-model="note" label="Escribe una nota" autofocus />
        </div>
        <div class="q-mb-md">
          <q-btn label="Cancelar" @click="cancelNoteDialogAction" color="negative" />
          <q-btn label="Guardar" @click="saveNote" color="positive" />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>


  <q-dialog v-model="showNoteDialogProduct">
    <q-card>
      <q-card-section>
        <div class="q-mb-md">
          <!-- Usamos v-model con noteProduct para que el input sea reactivo -->
          <q-input v-model="noteProduct" label="Escribe una nota" autofocus />
        </div>
        <div class="q-mb-md">
          <q-btn label="Cancelar" @click="cancelNoteDialogActionProduct" color="negative" />
          <q-btn label="Guardar" @click="addNote" color="positive" />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>

import SearchCliente from '../components/SearchCliente.vue'
import { createPedido, aprobPedido } from "@/router/router";
import { ref } from 'vue';
import Cookies from 'js-cookie';
import { AtomSpinner } from 'epic-spinners'
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification';
import { useQuasar } from 'quasar';

export default {
  components: {
    SearchCliente,
    AtomSpinner
  },
  name: 'OrderView',
  setup() {
    const order = ref(JSON.parse(localStorage.getItem('order')) || []);
    const selectedProduct = ref(null);
    const showPopup = ref(false);
    let isLoading = ref(false);
    let loadingMessage = ref('');
    let isRed = ref(false);
    const userPWAData = ref(Cookies.get('userPWA') ? JSON.parse(Cookies.get('userPWA')) : null);
    const toast = useToast();
    const $q = useQuasar();
    const showNoteDialog = ref(false);
    const note = ref('');
    const cancelNoteDialog = ref(false);
    const showNoteDialogProduct = ref(false);
    let noteProduct = ref('');
    const selectProducts = ref(null);


    // Función para mostrar el diálogo de confirmación
    const confirmWithNote = () => {
      $q.dialog({
        title: "Confirmar Pedido",
        message: "¿Desea agregar una nota al pedido?",
        persistent: true,
        ok: {
          label: "Sí",
          color: "positive",
        },
        cancel: {
          label: "No",
          color: "negative",
        },
      }).onOk(() => {
        showNoteDialog.value = true;
      }).onCancel(() => {
        confirmOrder();
      });
    };

    const cancelNoteDialogAction = () => {
      showNoteDialog.value = false;
    };

    const saveNote = () => {

      showNoteDialog.value = false;
      confirmOrder();
    };

    // Función para validar el tipo de producto antes de añadirlo al pedido
    const validateProductType = (product, order, toast) => {
      const existingProduct = order.find(item => item.id === product.id);
      const currentQuantity = existingProduct ? existingProduct.cantidad + product.cantidad : product.cantidad;
      const productCar = currentQuantity <= product.availibilityCount;

      const showError = (message) => {
        toast.error(message, {
          panelClass: "error",
          verticalPosition: "top",
          duration: 5000,
        });
      };

      switch (product.tipoabc) {
        case 'A':
        case 'V':
          // Productos tipo 'A' o 'V' pueden agregarse sin validación de inventario
          return true;

        case 'D':
        case 'O':
        case 'P':
          // Validación de inventario para productos tipo 'D', 'O', 'P'
          if (productCar) return true;
          showError(`No puedes agregar más productos. Disponibles: ${product.availibilityCount} y ya has agregado ${existingProduct.cantidad ? existingProduct.cantidad : product.cantidad}`);
          return false;

        case 'B':
          // Validación adicional según el crédito del cliente
          if ((userPWAData.value.plazo === 'F' && productCar) || userPWAData.value.plazo === 'R') return true;
          showError(`No puedes agregar más productos. Disponibles: ${product.availibilityCount} y ya has agregado ${existingProduct.cantidad ? existingProduct.cantidad : product.cantidad}`);
          return false;

        case 'C':
        case 'X':
          // Validación adicional según el plazo y fecha de estudio
          if (userPWAData.value.plazo === 'F') {
            if (productCar) return true;
            showError(`No puedes agregar más productos. Disponibles: ${product.availibilityCount} y ya has agregado ${existingProduct.cantidad ? existingProduct.cantidad : product.cantidad}`);
            return false;
          } else if (userPWAData.value.plazo === 'R' && (productCar || product.fechaEstudio < 45)) {
            return true;
          }
          showError(`No puedes agregar más productos. Disponibles: ${product.availibilityCount} y ya has agregado ${existingProduct.cantidad ? existingProduct.cantidad : product.cantidad}`);
          return false;

        default:
          showError(`No puedes agregar más productos. Disponibles: ${product.availibilityCount} y ya has agregado ${existingProduct.cantidad ? existingProduct.cantidad : product.cantidad}`);
          return false;
      }
    };

    // Función para gestionar la cantidad seleccionada por el cliente
    const valorCliente = (product) => {
      let order = JSON.parse(localStorage.getItem("order")) || [];

      if (product.cantidad < 1) {
        toast.error("No puedes seleccionar menos de un item", { timeout: 3000 });
        product.cantidad = 1;
        return;
      }



      // Validar antes de actualizar la cantidad
      if (!validateProductType(product, order, toast)) {
        product.cantidad = product.availibilityCount;
      }

      // Actualiza el producto en el carrito
      order = order.map(item =>
        item.id === product.id ? { ...item, cantidad: product.cantidad } : item
      );


      localStorage.setItem("order", JSON.stringify(order));
    };


    const getProductImage = (fotoEcom) => {
      const imageUrl = fotoEcom?.split(',')[0];
      return imageUrl
        ? `https://ep1.elpunto.com.co/puntocadenaimages/${imageUrl}`
        : 'https://ep1.elpunto.com.co/puntocadenaimages/000000000.jpg';
    };

    const openImagePopup = (product) => {
      product.value = { ...product }; // Clonar el producto para edición
      showPopup.value = true;
    };

    const closeImagePopup = () => {
      showPopup.value = false;
    };

    const updatecantidad = (productId) => {
      order.value = order.value.map(item =>
        item.id === productId ? { ...item, cantidad: selectedProduct.value.cantidad } : item
      );
      localStorage.setItem('order', JSON.stringify(order.value));
      closeImagePopup();
    };

    const removeFromOrder = (productId) => {
      order.value = order.value.filter(item => item.id !== productId);
      localStorage.setItem('order', JSON.stringify(order.value));
    };



    const confirmOrder = async () => {

      isLoading.value = true;
      loadingMessage.value = "Enviando información del pedido...";

      try {
        const orderData = JSON.parse(localStorage.getItem('order'));

        const storedUser = JSON.parse(Cookies.get('userPWA'));

        const idPedido = {
          nit: storedUser.nit,
          nota: note.value,
          nombreClienteGeneral: '',
          nitOpcional: '',
          centroCosto: storedUser.nit,
          vendedor: storedUser.codVen,
          userName: storedUser.userName,
          accion: '',
          logInterno: storedUser.logInterno,
          puntoEnvio: storedUser.codigoPE,
          ordenCompra: '',
          tipoOperacion: 'aproPwa',
          cuponCode: '',
          valorCupon: 0,
          userModify: storedUser.userName
        }


        loadingMessage.value = "Creando pedido...";
        const resp = await createPedido(idPedido);

        if (resp.data) {
          loadingMessage.value = "Enviando detalles del pedido...";
          const data = {
            nit: storedUser.nit,
            idPedido: resp.data.toString(),
            usuario: storedUser.userName,
            perfil: JSON.stringify(storedUser),
            logInterno: storedUser.logInterno,
            destino: 5,
            fechaPausa: '',
            nota: note.value,
            estado: 'SENVI',
            pedidos: orderData,
            puntoEnvio: storedUser.codigoPE,
            ordenCompra: '',
            tipoOperacion: 'aproPwa',
            cuponCode: '',
            valorCupon: 0,
            userModify: storedUser.userName
          }
          try {
            const respDetalle = await aprobPedido(data);


            if (respDetalle) {
              Swal.fire({
                title: `Pedido ${data.idPedido} Enviado`,
                text: respDetalle.data.message,
                icon: 'success',
                confirmButtonText: 'OK',
                allowOutsideClick: false,
                customClass: {
                  confirmButton: 'swal-confirm-button', // Puedes usar esta clase para personalizar el botón
                },
              });
              order.value = [];
              localStorage.removeItem('order');
              this.$router.push('/search')
            }
          } catch (error) {
            Swal.fire({
              title: `Pedido ${resp.data} Enviado`,
              text: error.response.data.message,
              icon: 'success',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              customClass: {
                confirmButton: 'swal-confirm-button', // Puedes usar esta clase para personalizar el botón
              },
            });
            order.value = [];
            localStorage.removeItem('order');
            this.$router.push('/search')

          }
        }

      } catch (error) {
        console.error("Error al procesar el pedido:", error);
        loadingMessage.value = "Hubo un error al procesar el pedido.";
      } finally {
        // Finaliza el loading
        isLoading.value = false;
        loadingMessage.value = '';  // Limpiar el mensaje de estado
      }
    };


    const formatcantidad = (cantidad) => {
      return Number(cantidad).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    };


    // Función para decrementar la cantidad
    const decreasecantidad = (product) => {
      let order = JSON.parse(localStorage.getItem("order")) || [];

      if (product.cantidad > 1) {
        product.cantidad -= 1;

        order = order.map(item =>
          item.id === product.id ? { ...item, cantidad: product.cantidad } : item
        );

        localStorage.setItem("order", JSON.stringify(order));
      } else {
        toast.error("No puedes seleccionar menos de un item", { timeout: 3000 });
      }

      // Sincronizar los cambios en el carrito
      localStorage.setItem("order", JSON.stringify(order));
    };

    // Función para incrementar la cantidad de un producto en el pedido
    const increasecantidad = (product) => {
      let order = JSON.parse(localStorage.getItem("order")) || [];

      // Validar antes de incrementar la cantidad
      if (!validateProductType(product, order, toast)) return;

      product.cantidad += 1;

      // Actualiza el carrito en localStorage
      order = order.map(item =>
        item.id === product.id ? { ...item, cantidad: product.cantidad } : item
      );
      localStorage.setItem("order", JSON.stringify(order));
      toast.success("Cantidad incrementada correctamente!", { timeout: 3000 });

    };

    const calculateTotalPrice = () => {
      return order.value.reduce((total, product) => total + product.price * product.cantidad, 0);
    };

    const calculateTotalIva = () => {
      const totalIva = order.value.reduce((total, product) => {
        const ivaProducto = ((product.price * product.iva) / 100) * product.cantidad;
        return total + ivaProducto;
      }, 0);
      return totalIva;
    };

    const calculTotalConIva = () => {
      return calculateTotalPrice() + calculateTotalIva();
    };

    // Función para abrir el modal y mostrar la nota del producto
    const openNoteDialogProduct = (product) => {
      // Asignamos la nota del producto al campo noteProduct

      selectProducts.value = product
      noteProduct.value = product.nota || ""; // Si no hay nota, asignamos un string vacío
      showNoteDialogProduct.value = true;  // Mostramos el diálogo
    };




    // Función para guardar la nota en el producto
    const addNote = () => {

      let order = JSON.parse(localStorage.getItem("order")) || [];

      // Actualizamos la nota del producto en el carrito
      order = order.map(item =>
        item.id === selectProducts.value.id ? { ...item, nota: noteProduct.value } : item
      );

      // Guardamos el pedido actualizado en localStorage
      localStorage.setItem("order", JSON.stringify(order));

      // Cerramos el popup
      showNoteDialogProduct.value = false;
      toast.success("Nota del producto actualizada correctamente", { timeout: 3000 });
    };

    const cancelNoteDialogActionProduct = () => {
      showNoteDialogProduct.value = false;
    };

    return {
      order,
      selectedProduct,
      showPopup,
      getProductImage,
      openImagePopup,
      closeImagePopup,
      updatecantidad,
      removeFromOrder,
      calculateTotalPrice,
      confirmOrder,
      formatcantidad,
      decreasecantidad,
      increasecantidad,
      loadingMessage,
      isLoading,
      calculTotalConIva,
      calculateTotalIva,
      valorCliente,
      userPWAData,
      isRed,
      addNote,
      cancelNoteDialog,
      confirmWithNote,
      showNoteDialog,
      note,
      cancelNoteDialogAction,
      saveNote,
      cancelNoteDialogActionProduct,
      showNoteDialogProduct,
      noteProduct,
      openNoteDialogProduct,



    };
  },
};
</script>
<style scoped>
.order-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
}



.product-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 1rem;
  width: 100%;
  max-width: 400px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-info {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  text-align: justify;
}

.product-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.product-price {
  font-size: 1.1rem;
  font-weight: bold;
  color: #233a7a;
  margin-bottom: 0.5rem;
}

.product-cantidad {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.product-actions {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
}

.show-image-button {
  padding: 0.3rem 0.5rem;
  background-color: #bdc3c7;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 0.5rem;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.show-image-button:hover {
  background-color: #95a5a6;
}

.remove-from-list-button {
  padding: 0.5rem 1rem;
  background-color: #233a7a;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-from-list-button:hover {
  background-color: #c0392b;
}

.order-summary {

  text-align: center;
}

.total-price {
  font-size: 1.25rem;
  font-weight: bold;
  color: #233a7a;
}

.confirm-order-button {
  padding: 0.75rem 1.5rem;
  background-color: #233a7a;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.confirm-order-button:hover {
  background-color: #1a2a55;
}

.back-to-products-link {
  margin-top: 1rem;
  color: #233a7a;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.back-to-products-link:hover {
  color: #2980b9;
}

.no-products {
  color: #666;
  font-size: 1.1rem;
  margin-top: 2rem;
  text-align: center;
}

/* Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: #fff;
  padding: 2rem;
  border-radius: 12px;
  position: relative;
  max-width: 500px;
  width: 65%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-image {
  max-width: 86%;
  height: auto;
  border-radius: 8px;
}

.close-popup-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
}

.close-popup-button:hover {
  background: #c0392b;
}

.popup-details {
  margin-top: 1rem;
}

.cantidad-input {
  width: 6rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 0 0.5rem;
  box-sizing: border-box;
}

.update-cantidad-button {
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.update-cantidad-button:hover {
  background-color: #2980b9;
}

.cantidad-button {
  background-color: transparent;
  color: #b3bcc7;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  width: 2.5rem;

  transition: background-color 0.3s ease;

  font-size: 42px;
}

.cantidad-button:hover {
  background-color: #a3a3a3;
}

.tabli {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}


.table td {
  width: 50%;
  padding: 10px;
  /* border: 1px solid #ddd; */
  text-align: left;
  vertical-align: middle;
  box-sizing: border-box;
}

.tabli td {
  font-size: 16px;
}






.info {
  color: #b3bcc7;
}

.infonNa {
  color: black;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 76vh;
}

.products {
  display: flex;
  height: 57vh;
  overflow: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

header,
footer {
  position: sticky;
  z-index: 10;
}

header {
  top: 0;
}

footer {
  bottom: 0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.loading-popup p {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  /* Texto en color oscuro */
}

.resumen {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.titulo {
  font-weight: bold;
  margin-right: 5px;
}



@media (max-width: 700px) {
  .resumen {
    display: block;

    font-size: 14px;
  }

  .total-price {

    font-size: 14px;
  }

  .order-summary {
    display: table-cell;
  }

  .order-container {
    width: 100%;
  }

  .app-container {
    width: 100%;
  }

  .infonNa {
    font-size: 16px;
  }

  .info {
    font-size: 16px;
  }

  .popup-image {
    max-width: 59%;
  }

  .product-details {
    text-align: center;
  }

  .info1 {
    display: contents;
  }

  .info2 {
    display: contents;
  }

  .info3 {
    display: contents;
  }

  .info4 {
    display: contents;
  }
}

.input-rojo {
  color: red;
}

.table-row {
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.table-row td {
  padding: 8px;
  line-height: 0.6;
  font-size: 11px;
}

.input-nota {
  border-radius: 5px 5px 5px 5px;
}

.note {
  display: flex;
  position: relative;
}

.note p {
  margin: 13px;
  border-radius: 5px 5px 5px 5px;
}

.note textarea {
  border-radius: 5px 5px 5px 5px;
}
</style>
