import { createApp } from 'vue';
import App from './App.vue';
import router from './router/router';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Importando los íconos de FontAwesome
import { faTrash, faXmark, faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Importando Quasar y las opciones de usuario
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';

// Importando Vuetify
import { createVuetify } from 'vuetify';

// Configuración de Vuetify
const vuetify = createVuetify();

// Agregando los íconos de FontAwesome a la librería
library.add(faTrash, faXmark, faUser, faLock);

// Crear la instancia de Vue
const app = createApp(App);

// Configurando Quasar
app.use(Quasar, quasarUserOptions);

// Configurando Vuetify (puedes eliminarlo si no deseas usarlo)
app.use(vuetify);

// Otros plugins (router y toast)
app.use(router);
app.use(Toast);

// Registrar FontAwesome como componente global
app.component('font-awesome-icon', FontAwesomeIcon);

// Montar la aplicación
app.mount('#app');
