import './styles/quasar.scss';
import '@quasar/extras/material-icons/material-icons.css';

// Importar los plugins de Quasar que vas a usar
import Dialog from 'quasar/src/plugins/dialog/Dialog.js';;

// Para ser usado en app.use(Quasar, { ... })
export default {
  config: {
    // Puedes configurar opciones adicionales aquí si lo deseas
  },
  plugins: {
    Dialog, // Asegúrate de agregar el plugin Dialog
  }
};
